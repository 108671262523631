import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import DangerBox from 'components/Web_User_Interface/720p_Series/System/User/DangerBox';
import UserTable from 'components/Web_User_Interface/720p_Series/System/User/userTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "System Menu // User Management",
  "path": "/Web_User_Interface/720p_Series/System/User/",
  "dateChanged": "2017-12-10",
  "author": "Mike Polinowski",
  "excerpt": "Your camera will be set up with 3 user classes - administrator, user and visitor - and one password for all of them - instar. Please change this as soon as you want to forward your camera to the internet. And don´t forget to change all three of them.",
  "image": "./WebUI_720p_SearchThumb_System_User.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_System_User.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='System Menu // User Management' dateChanged='2017-12-10' author='Mike Polinowski' tag='INSTAR IP Camera' description='Your camera will be set up with 3 user classes - administrator, user and visitor - and one password for all of them - instar. Please change this as soon as you want to forward your camera to the internet. And don´t forget to change all three of them.' image='/images/Search/WebUI_720p_SearchThumb_System_User.png' twitter='/images/Search/WebUI_720p_SearchThumb_System_User.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/System/User/' locationFR='/fr/Web_User_Interface/720p_Series/System/User/' crumbLabel="User Management" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "system-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system-menu",
        "aria-label": "system menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Menu`}</h2>
    <h3 {...{
      "id": "user-management",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#user-management",
        "aria-label": "user management permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`User Management`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`Your camera will be set up with 3 user classes - administrator, user and visitor - and one password for all of them - instar. Please change this as soon as you want to forward your camera to the internet. And don´t forget to change all three of them. You can use the RTSP Stream if you want to allow users to access your camera without the hassle of a password.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/39a412f78964d2e292606cc7965e09fd/6bbf7/WebUI-System_User.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAAsTAAALEwEAmpwYAAADIElEQVQ4y22T226iWhjHfa+d2c8xh6s+waT7KWbum6Yme8Zpqh2nzdgiSoCKVIEEqmhaDxVQhAUsEJCDJLvpVXdA29pOfxfkW8n6+P/Xd8hVzs4/fvr017u/33/4QNO0IAgcx/K8MBqNxi8ZDoez2axSqezs7Hz7NyWHorV8Pv/l69dCoTCZTGRZHg6Hc03zfX/5B0EQuK57cHBAUc3Ly8vccUalckbT9FRRBoMBhHYURcu3cF3X8zwcx/sZuWKxuL+/XyodUxQ1nSqj0chxnKfbr/S9DARBOp1Ot9vNFQo/9vb2yuUyQZCz2azf7wMAkiSJomi1WqmqenNzY9tOkiRxHGe2vWq12s3I0TTdumzdXKfoum4Yhmma/hae5/kveU6WJen6+vp2fGtZ0DAMUeyOx7dpZTLWRUqDIIjCUNZtAzo1tJq57ubOz893P3/e3f2nWq36vq/ruuM427Lbyou0XlvKlmk6Nnx4eLi7uzNN07bTUj/VKQiCKIrWFrJj+kWQx2SgAwCMJEnCKLIsczgYAB0EQeB53nK5hNCaTCYWhOvKv34zhNAAehiGcRxDCNfij09OfwEh9DxvfQzDMFNGNsm/Tk6Ojo4ODw8JgpjP1fFopMjKWvbJ+VNgmqbjOCiKbpIL37/n83kUreE4rmmaosgzVX1zvIIgsCxrsVg82z5M+VEqlep1TNM0AMCfOa/iZ9tNuolhWL1eZxjWMAAAYLFYbLdqPc9vDwnHcRTVaDQaV1dX0LJkWTZNc92eKI4dx5EkyXacKIrCjBfKDMNeXFyQJCkIAgBAVVVN03SQdlBVVUWWNX2Dpmnz+dx13WdlnuepJtWkaVEUVVXt9/sCzzPtNstyrVYLw7B2u81xHMuyoihmS2KjKLrZKo7lSJKgGpQgCLZtr1YrXdOkyWSmqtIkRZLk6XSqyIrrunEcr1dyo9wVRZ7nO51Or9fzff/+/j5JkrTPnrewbdd1l76/9P0gCP57pFarbZTbbabZbFIUxfM8wzAkSWIYhiDI7+Pjys+fZ6enlXL57OS0iqI4jlMURRBEsVjs9XqdTud/0NAbqsRxVBoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/39a412f78964d2e292606cc7965e09fd/e4706/WebUI-System_User.avif 230w", "/en/static/39a412f78964d2e292606cc7965e09fd/d1af7/WebUI-System_User.avif 460w", "/en/static/39a412f78964d2e292606cc7965e09fd/04ac8/WebUI-System_User.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/39a412f78964d2e292606cc7965e09fd/a0b58/WebUI-System_User.webp 230w", "/en/static/39a412f78964d2e292606cc7965e09fd/bc10c/WebUI-System_User.webp 460w", "/en/static/39a412f78964d2e292606cc7965e09fd/d8378/WebUI-System_User.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/39a412f78964d2e292606cc7965e09fd/81c8e/WebUI-System_User.png 230w", "/en/static/39a412f78964d2e292606cc7965e09fd/08a84/WebUI-System_User.png 460w", "/en/static/39a412f78964d2e292606cc7965e09fd/6bbf7/WebUI-System_User.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/39a412f78964d2e292606cc7965e09fd/6bbf7/WebUI-System_User.png",
              "alt": "Web User Interface - 720p Series - System User",
              "title": "Web User Interface - 720p Series - System User",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <UserTable mdxType="UserTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The web user interface offers a 3-level user management with different access rights for administrators, operators and visitors. You can set user names and passwords for all three user levels in the Users mask. Guests will only have access to the video stream of the camera, Users in addition are able to use the pan & tilt function and only the Administrator will have access to all the camera's configuration menus.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      